<template>
	<div>
		<div>
			<el-table ref="multipleTable" :data="orderlist"  tooltip-effect="dark" style="width: 100%">
				<el-table-column   show-overflow-tooltip  label="文章标题">
					<template slot-scope="scope">
						<div class="ordertable" >
							<div class="ygdleft">
								<p class="biaoti">{{scope.row.title}}</p>
								<p><span>{{scope.row.name}}</span>&nbsp;<span>{{scope.row.editor}}</span></p>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column    label="按投稿时间排序"  prop="operation_time" align="center" sortable>
					<template slot-scope="scope">
						<div class="ordertable" >
							<div class="ygdright">
								<div style="height: 20px;"></div>
								<span><i class="el-icon-s-promotion"></i>投稿时间：{{scope.row.operation_time}}</span>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column    label="按审批时间排序"  prop="Approval_time" align="center" sortable>
					<template slot-scope="scope">
						<div class="ordertable" >
							<div class="ygdright">
								<div style="height: 20px;"></div>
								<span><i class="el-icon-s-custom"></i>审批时间：{{scope.row.Approval_time}}</span>
							</div>
						</div>
					</template>
				</el-table-column>
        
			
				<el-table-column  label="操作"   width="100px">
					<template slot-scope="scope">
						<div class="ordertable" >
							<div class="ygdright">
								<div style="height: 20px;"></div>
								<span @click="showDetail(scope.row.works_id)" style="cursor: pointer;">查看详情</span>
							</div>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<!-- <div class="orderlist" v-for="(item,key) in orderlist"  @click="showDetail(item.works_id)">

				<div class="ygdleft">
					<p class="biaoti">{{item.title}}</p>
					<p><span>{{item.name}}</span><span>{{item.editor}}</span></p>
				</div>
				<div class="ygdright">
					<div class="urgent" v-show="item.attribute==1">
						加急
					</div>
					<div style="height: 20px;"></div>
					<span> <i class="el-icon-s-promotion"></i>投稿时间：{{item.operation_time}}</span>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
//	import { ordernum } from '@/API/YGD/orderdetail';
//	import { ordertrace } from '@/API/YGD/orderdetail';
//	import { orderarti } from '@/API/YGD/orderdetail';

	export default {
		data() {
			
			return {
				src: "",
			}
		},
		methods: {
			showDetail(id) {
        console.log(id);
				this.$router.push({path:this.$parent.childpath,query:{id:this.$parent.now,wid:id}})
			}, //查询订单详情状态和编号
			ordersnum(id) {
				ordernum(id).then(res => {
					this.$parent.num = res.data.data.contributionnumber
					this.$parent.active = res.data.data.state
				})
			},
			//查看追踪
			orderstrace(id) {
				ordertrace(id).then(res => {
        this.$parent.num = res.data.data.contributionnumber
        this.$parent.active = res.data.data.state
      })
    },
    //查看追踪
    orderstrace (id) {
      ordertrace(id).then(res => {

        this.$parent.trace = res.data.data
      })
    },

			//查看文章详情
			ordersarti(id) {
				orderarti(id).then(res => {
					this.src = this.URL + res.data.data.content
					this.$parent.article = res.data.data
					console.log(this.src)
				})
			}
		},
		props: ["orderlist"],
		mounted(){
			
		}
	}


</script>

<style>
.orderlist {
  position: relative;
  display: flex;
  justify-content: space-between;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.biaoti {
  font-size: 14px;
}

.orderlist span {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin: 0 3px;
}

.urgent {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 24px;
  background: #ff1918;
  border-radius: 0px 4px 0px 4px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
}
.nourgent{
	position: absolute;
	right: 0;
	top: 0;
	width: 40px;
	height: 24px;
	background:lightseagreen;
	border-radius: 0px 4px 0px 4px;
	color: #fff;
	text-align: center;
	font-size: 12px;
	line-height: 24px;
}
.ygdPage {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>